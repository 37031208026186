
@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito_Sans'), url('./fonts/Nunito_Sans/NunitoSans-Regular.ttf')
}

@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair_Display'), url('./fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf')
}

@font-face {
  font-family: 'Dancing Script';
  src: local('Dancing_Script'), url('./fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf')
}

$theme-colors: (
  'primary': #252739,
  'secondary': #1c242c,
  'ternary': #23a455,
  'ghost': #fff,
  'danger': #ff0000
);

$theme-one-btn: #23a455;
$theme-two-btn: #B40101;
$theme-three-btn: #BEAF87;
$theme-four-btn: #012169;
$theme-five-btn: #dc1c2e;
$theme-six-btn: #C82021;

$theme-one-main-background: #252739;
$theme-two-main-background: #343342;
$theme-three-main-background: #252526;
$theme-four-main-background: #545759;
$theme-five-main-background: #003da5;
$theme-six-main-background: #333;

$theme-one-card-background: #2e2e3d;
$theme-two-card-background: scale-color($theme-two-main-background, $lightness: -10%);
$theme-three-card-background: scale-color($theme-three-main-background, $lightness: -10%);
$theme-four-card-background: scale-color($theme-four-main-background, $lightness: -20%);
$theme-five-card-background: scale-color($theme-five-main-background, $lightness: -10%);
$theme-six-card-background: scale-color($theme-six-main-background, $lightness: -10%);

$theme-one-agent-background: #212529;
$theme-two-agent-background: scale-color($theme-two-main-background, $lightness: -25%);
$theme-three-agent-background: scale-color($theme-three-main-background, $lightness: -25%);
$theme-four-agent-background: scale-color($theme-four-main-background, $lightness: -50%);
$theme-five-agent-background: scale-color($theme-five-main-background, $lightness: -25%);
$theme-six-agent-background: scale-color($theme-six-main-background, $lightness: -25%);

$theme-one-main-font: #fff;
$theme-two-main-font: #fff;
$theme-three-main-font: #fff;
$theme-four-main-font: #fff;
$theme-five-main-font: #fff;
$theme-six-main-font: #fff;

.theme-one  {
  background-color: $theme-one-main-background;
  .variable-buttons {
    background-color: $theme-one-btn !important;
  }
  .top-buttons {
    background-color: $theme-one-main-background;
  }
  .top-buttons:hover {
    background-color: $theme-one-main-background;
    color: $theme-one-main-font !important;
  }
  .variable-checks {
    color: $theme-one-btn !important;
  }
  .variable-main-font-color {
    color: $theme-one-main-font;
  }
  .btn {
    border: none;
  }
  .desc-card-bg-color {
    background-color: #2e2e3d;
  }
  .profile-agent-sect-color {
    background-color: #1c242c;
  }
}

.theme-two {
  background-color: $theme-two-main-background;
  .variable-buttons {
    background-color: $theme-two-btn !important;
  }
  .top-buttons {
    background-color: $theme-two-main-background;
  }
  .top-buttons:hover {
    background-color: $theme-two-main-background;
    color: $theme-two-main-font !important;
  }
  .variable-checks {
    color: $theme-two-btn !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-two-main-font !important;
  }
  .desc-card-bg-color {
    background-color: $theme-two-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-two-agent-background;
  }
}

.theme-three  {
  background-color: $theme-three-main-background;
  .variable-buttons {
    background-color: $theme-three-btn !important;
  }
  .variable-checks {
    color: $theme-three-btn !important;
  }
  .top-buttons {
    background-color: $theme-three-main-background;
  }
  .top-buttons:hover {
    background-color: $theme-three-main-background;
    color: $theme-three-main-font !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-three-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-three-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-three-agent-background;
  }
}

.theme-four {
  background-color: $theme-four-main-background;
  .variable-buttons {
    background-color: $theme-four-btn !important;
  }
  .variable-checks {
    color: $theme-four-btn !important;
  }
  .top-buttons {
    background-color: $theme-four-main-background;
  }
  .top-buttons:hover {
    background-color: $theme-four-main-background;
    color: $theme-four-main-font !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-four-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-four-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-four-agent-background;
  }
}

.theme-five  {
  background-color: $theme-five-main-background;
  .variable-buttons {
    background-color: $theme-five-btn !important;
  }
  .variable-checks {
    color: $theme-five-btn !important;
  }
  .top-buttons {
    background-color: $theme-five-main-background;
  }
  .top-buttons:hover {
    background-color: $theme-five-main-background;
    color: $theme-five-main-font !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-five-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-five-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-five-agent-background;
  }
}

.theme-six {
  background-color: $theme-six-main-background;
  .variable-buttons {
    background-color: $theme-six-btn !important;
  }
  .variable-checks {
    color: $theme-six-btn !important;
  }
  .top-buttons {
    background-color: $theme-six-main-background;
  }
  .top-buttons:hover {
    background-color: $theme-six-main-background;
    color: $theme-two-main-font !important;
  }
  .btn {
    border: none;
  }
  .variable-main-font-color {
    color: $theme-six-main-font;
  }
  .desc-card-bg-color {
    background-color: $theme-six-card-background;
  }
  .profile-agent-sect-color {
    background-color: $theme-six-agent-background;
  }
}

  .carousel-button:hover {
    background-color: transparent !important;
    color: black !important;
  }

  .blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .carousel-control-prev, .carousel-control-next {
    width: 5% !important;
    height: 50% !important;
    bottom: 4.5rem !important;
    top: auto !important;
  }

  .clipboard:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .profile-sect {
    display: none !important;
  }

 .sched-show-button {
  font-size: 14px !important;
 }

.desc-card-bg {
  width: 90%;
  border-radius: 10px;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.desc-icon-sect {
  padding-top: .25rem;
  padding-bottom: .13rem;
}

.desc-text-sect {
  padding-top: .13rem;
  padding-bottom: .25rem;
}

.carousel-indicators {
  position: relative !important;
}

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-next-icon {
  display: none !important;
}

.carousel-inner {
  height: 100% !important;
}

.profile-img-sect-color {
 background-color: white;
}

.profile-img-sect {
  height: 35%;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-size: cover;
}

.profile-date-sect {
  margin: 0;
}

 .profile-desc-sect {
   border-radius: 65px 65px 0 0;
   border-top-style: solid;
   border-top-color: white;
   border-top-width: 1px;
 }
 
 .profile-agent-sect {
   border-radius: 45px 45px 0 0;
 }

.zip-inputs {
  padding: 2px !important;
}

.portal-inputs {
 width: 60%;
}

.lg-portal-inputs {
  width: 80%;
}

.portal-div-inputs {
  display: flex;
  justify-content: center;
}

.profile-pic-circle {
  height: 100px;
  width: 100px;
  display: inline-block;
}

.agent-headshot-circle {
  height: 50px;
  width: 50px;
  background-color: #c4c4c4;
  border-radius: 50%;
  display: inline-block;
}

.profile-pic-img {
  object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}

.profile-pic-img-profile {
  object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
}

.theme-picker-circles {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: .44px;
}

.theme-picker {
  text-align: center;
}

.alert-icon {
  width: 7rem;
  height: 7rem;
}

.portal-top-btn {
  padding: 0 !important;
}

.genie-img {
  width: 25px;
  height: 25px;
}

.auth-back-img {
  width: 20px;
  height: 20px;
  vertical-align: sub;
}

.genie-auth-img {
  width: 60px;
  height: 60px;
  color: #c4c4c4;
}

.expand-icon {
  width: 35px;
  height: 35px;
  color: black;
}

.directions-icon {
  width: 45px;
  height: 45px;
}

.ternary-background-color {
  background-color: #23a455;
}

.gray-background-color {
  background-color: #c4c4c4;
}

.no-display {
  display: none !important;
}

.height-one-hundred {
  height: 100%;
}

.auth-error-messages {
  margin: 0 0 0 0;
  color: white;
  font-size: .7rem;
}

.portal-error-messages {
  margin: 0 0 0 0;
  color: white;
  font-size: .6rem;
}

.portal-form-error-messages {
  margin: 0 0 0 0;
  color: black;
  font-size: .7rem;
  font-weight: bold;
}

.auth-container {
  height: 100vh;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
}

.profile-container {
  height: 100vh;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
}

.auth-form-bottom-text {
  font-size: .7rem;
}

.auth-form-div {
  background-color: #252739;
  border-radius: 45px 45px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-form-div-reset {
  background-color: #c4c4c4;
  border-color: #252739;
  border-style: solid;
  border-width: .1rem;
  border-radius: 45px 45px 0px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth-form-form {
  width: 80%;
}

.auth-form-form-group {
  margin-bottom: 1rem;
}

.auth-form-div-wrapper {
  height: 100%;
}

.auth-progressbar-row {
  display: flex;
  height: .2rem;
  margin-top: 2rem !important;
  margin-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.navbar-padding {
  padding: 3rem, 0, 3rem, 0;
}

.main-font-style {
  font-family: 'Nunito Sans', sans-serif;
}

.secondary-font-style {
  font-family: 'Playfair Display', sans-serif;
}

.fancy-font-style {
  font-family: 'Dancing Script';
}

.nav-text {
  letter-spacing: 0.09rem;
}

.section-one-landing-vertical-spacing-wrapper {
  height: calc(100vh - 90px);
}

.section-one-landing-vertical-spacing {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 !important;
}

.section-one-landing {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bg-image-landing {
  position: absolute;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-size: cover;
  z-index: -1;
}


.bg-image-filter {
  position: absolute;
  background-color: #1c242cf2;
  min-height: 100vh;
  width: 10vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

.section-one-landing-text {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
}

.landing-nav {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-right: 20px !important;
}

.section-two-landing {
  padding: 0 !important;
}

.section-two-landing-heading {
  background-color: #edf1f2
}
.green {
  color: #23a455
}

.black {
  color: black !important;
  font-weight: bold;
}

.white {
  color: white !important;
}

.red {
  color: red !important;
}

.secondary-font-color {
  color: #1c242c;
}

.xs-text {
  font-size: 8px;
}

.small-text {
  font-size: 12px;
}

.small-text-profile {
  font-size: 12px;
}

.med-text-profile {
  font-size: 18px;
}

.med-text {
  font-size: 18px;
}

.large-text {
  font-size: 24px !important;
}

.xl-text {
  font-size: 36px !important;
}

.green-divider {
  background-color: #23a455;
  height: 1px;
  width: 40%;
}

.landing-page-btn {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.shadow-btn {
  box-shadow: 0 0.4rem .2rem rgba(148, 112, 112, 0.35);
}

.landing-sect3-cont-rows-text {
  display: flex;
  align-items: center;
}

.landing-sect3-cont-rows-images {
  padding: 0 !important;

}

.landing-sect3-container {
  margin-bottom: 1rem !important;
}

.landing-sect3-cont1-img {
  width: 100px;
  height: 100px;
}

.landing-sect3-cont2-img {
  width: 81.67px;
  height: 100px;
}

.landing-sect3-cont3-img {
  width: 91px;
  height: 100px;
}

.landing-cont4-img {
  width: 100px;
  height: 155.44px;
}

.landing-cont4-main-container {
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.landing-page-btn-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (min-height: 600px) {
  .profile-sect {
    display: block !important;
  }
}

@media (max-height: 601px) {
  .section-one-landing-vertical-spacing {
    display: block;
    overflow-y: scroll;
  }
  .auth-form-form {
    overflow-y: scroll;
    overflow-x: none;
  }
  .small-text-profile {
    font-size: 16px !important;
  }
  .med-text-profile {
    font-size: 20px !important;
  }
  .directions-icon {
    width: 35px !important;
    height: 35px !important;
  }
  .agent-headshot-circle {
    height: 60px !important;
    width: 60px !important;
  }
}

@media (min-width: 375px) {
  .sched-show-button {
    font-size: 1rem !important;
  }
  .small-text {
    font-size: 14px;
  } 
  .small-text-profile {
    font-size: 16px;
  }
  .med-text-profile {
    font-size: 22px;
  }
  .large-text {
    font-size: 30px !important;
  }
  .theme-picker {
    text-align: left;
  }
}

@media (min-width: 540px) {
  .desc-text-pos {
    top: 6rem;
  }
  .xs-text {
    font-size: 12px;
  }
  .med-text {
    font-size: 24px;;
  }
  .small-text {
    font-size: 18px;
  }
  .small-text-profile {
    font-size: 22px;
  }
  .med-text-profile {
    font-size: 30px;
  }
  .large-text {
    font-size: 36px !important;
  }
  .profile-pic-circle {
    width: 150px;
    height: 150px;
  }

  .agent-headshot-circle {
    height: 75px;
    width: 75px;
  }
  .theme-picker-circles {
    width: 24px;
    height: 24px;
  }
  .landing-sect3-cont-rows-text {
    text-align: center;
    display: flex;
    align-items: center;
  }
  
  .landing-sect3-cont-rows-images {
    padding: 0 !important;
    display: flex;
    justify-content: center;
  
  }
  .landing-sect3-container {
    margin-bottom: 3rem !important;
  }
  .portal-top-btn {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .account-info-button {
    margin-left: 2rem;
  }
  .theme-picker {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .sched-show-button {
    font-size: 1.5rem !important;
  }
  .carousel-control-prev-icon {
    display: inline-block !important;
  }
  .carousel-control-next-icon {
    display: inline-block !important;
  }
  .small-text {
    font-size: 24px;
  }
  .small-text-profile {
    font-size: 24px;
  }
  .med-text-profile {
    font-size: 32px;
  }
  .med-text {
    font-size: 32px;
  }
  .large-text {
    font-size: 46px !important;
  }
  .profile-pic-circle {
    width: 175px;
    height: 175px;
  }
  .agent-headshot-circle {
    height: 85px;
    width: 85px;
  }
  .landing-sect3-container {
    margin-bottom: 5rem !important;
  }
  .landing-sect3-cont1-img {
    width: 140px;
    height: 140px;
  }
  
  .landing-sect3-cont2-img {
    width: 114.34px;
    height: 140px;
  }
  
  .landing-sect3-cont3-img {
    width: 127.4px;
    height: 140px;
  }
  .landing-cont4-img {
    width: 198.03px;
    height: 217.62px;
  }
  .landing-cont4-main-container {
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  .section-two-landing {
    margin-bottom: 2rem;
  }
  .landing-page-btn-container {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .genie-img {
    width: 35px;
    height: 35px;
  }
  .genie-auth-img {
    width: 80px;
    height: 80px;
    color: #c4c4c4;
  }
  .expand-icon {
    width: 50px;
    height: 50px;
    color: black;
  }

  .directions-icon {
    width: 65px;
    height: 65px;
    color: #24a455;
  }
  .section-one-landing-text {
    height: calc(100vh - 65px);
  }
  .auth-form-bottom-text {
    font-size: 1rem;
  }
  .auth-container {
    border-style: solid;
    border-color: #252739;
    border-radius: 40px 40px 40px 40px;
  }

  .portal-container {
    border-style: solid;
    border-color: #252739;
    border-radius: 40px 40px 40px 40px;
  }
}

@media (min-width: 1024px) {
  .small-text {
    font-size: 24px;
  }
  .med-text {
    font-size: 30px;
  }
  .large-text {
    font-size: 38px !important;
  }
  .genie-img {
    width: 45px;
    height: 45px;
  }
  .genie-auth-img {
    width: 100px;
    height: 100px;
    color: #c4c4c4;
  }
  .expand-icon {
    width: 50px;
    height: 50px;
    color: black;
  }
  .directions-icon {
    width: 65px;
    height: 65px;
    color: #24a455;
  }
  .section-one-landing-text {
    height: calc(100vh - 70px);
  }
  .bg-image-landing {  
    background-position-y: 25%;
    }
}

@media (min-width: 1200px) {
  .small-text {
    font-size: 24px;
  }
  .med-text {
    font-size: 40px;
  }
  .large-text {
    font-size: 54px !important;
  }
  .landing-sect3-cont1-img {
    width: 200px;
    height: 200px;
  }
  
  .landing-sect3-cont2-img {
    width: 163.33px;
    height: 200px;
  }
  
  .landing-sect3-cont3-img {
    width: 182px;
    height: 200px;
  }
  .landing-cont4-img {
    width: 273px;
    height: 300px;
    margin-top: 50px !important;
  }
  .landing-agent-profile {
    text-align: center;
    padding-right: 200px !important;
    padding-left: 200px !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .landing-nav-sign-up {
    margin-right: 60px !important;
    text-decoration: none;
  }

  .nav-text {
    margin-left: 20px !important;
  }
  .genie-img {
    width: 50px;
    height: 50px;
  }
  .section-one-landing-text {
    height: calc(100vh - 80px);
  }
}




@import '~bootstrap/scss/bootstrap';